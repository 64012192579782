import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { PostDataTiktok } from '../types/tiktokEvents';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class TiktokEventsService {
  isLoading = false;

  private ENDPOINT = "https://back-integraciones.vercel.app/lideart/tiktok"

  private http = inject(HttpClient);
  private usuario = inject(LoginService);

  private savePrice(val: string | number): number{
    const tipo = typeof val;
    if(tipo == 'number'){
      val = val as number
      return parseFloat(val.toFixed(2));
    }
    else if (tipo === "string"){
      val = val as string
      const digits = val.replace(/[^\d.]/g, '');
      // Convierte la cadena resultante a número
      const number = parseFloat(digits);
      // Ajusta la precisión a 2 decimales
      return parseFloat(number.toFixed(2));
    }
    
    // console.error('TIKTOK-EVENTS => se registro un valor que no pudo ser convertido a numero');
    return 0
  }


  sendEvent(info: PostDataTiktok){
    const userData = this.usuario.getUserData();
    console.log("userData", userData)
    if (userData !== undefined){
      info.email = userData.correo;
      info.external_id = userData.idDynamics;
      info.phone = userData.telefono;
    }
    if (info.value != undefined){
      info.value = this.savePrice(info.value)
    }
    // console.log("Se envia un evento a tiktok con la siguiente informacion", info)
    
    this.isLoading = true
    this.http.post(this.ENDPOINT, info).subscribe(response => {
      this.isLoading = false
      // console.log('Respuesta del servidor:', response);
      return response;
    })
  }
}
